const getters = {
  // obtienen la info del estdo
  layout(state) {
    return state.layout;
  },

  getApp(state) {
    return state.app;
  },

  getOnBoardingBepay(state) {
    return state.onBoardingBepay;
  },

  getOnboardingImgBepay(state) {
    return state.onBoardingBepayImage;
  },

  getNotification(state) {
    return state.notification;
  },

  getUser(state) {
    return state.user;
  },

  countryList(state) {
    return state.countryList;
  },

  getLocalStore(state) {
    return state.locale;
  },

  getMenuStore(state) {
    return state.menu;
  },

  getshopsUser(state) {
    return state.shopsUser;
  },

  getSubMenuStore(state) {
    return state.submenu;
  },

  getMenusRolStore(state) {
    return state.menusrol;
  },
};

export default getters;
