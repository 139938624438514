import axios from 'axios';

//Defino el entorno si es producción o desarrollo
class AxiosCalls {
  baseURLws;
  baseURLPYPws;

  constructor() {
    //dev
    // this.baseURLws = 'http://localhost:5002/api/v1';
    // this.baseURLPYPws = 'http://localhost:8003';
    //produccion
    this.baseURLws = 'https://api.contento.tienda/api/v1';
    this.baseURLPYPws = 'https://epayment.contento.tienda';
    this.initializeToken(); // Inicializar el token al crear la instancia
  
  }

   // Función para inicializar el token desde localStorage
   initializeToken() {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    }
  }

  //---------------------------------------------
  //------------ BTN PAGO -----------------------
  // --------------------------------------------
  async ReturnImgbyURL(data) {
    const urlrequest = `${this.baseURLws}/ReturnImgbyURL`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  }

  async ApplyPurchaseDetailShippers(data) {
    const urlrequest = `${this.baseURLws}/apply-purchase-detail-shippers`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  }

  async CheckoutTransactionsStatusELPShippers(data) {
    const urlrequest = `${this.baseURLws}/elp/checkoutTransactionsStatusELPShippers`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  }

  async CheckoutTransactionsELPShippers(data) {
    const urlrequest = `${this.baseURLws}/elp/checkoutTransactionsshippers`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  }

  /*
    ██████  ██████   ██████  ██████  ██ 
    ██   ██ ██   ██ ██    ██ ██   ██ ██ 
    ██   ██ ██████  ██    ██ ██████  ██ 
    ██   ██ ██   ██ ██    ██ ██      ██ 
    ██████  ██   ██  ██████  ██      ██
  */
  async newOrderDropi(data) {
    // RUTA QUEMADA SE PASA EL IDAPP
    const urlrequest = `https://test-api.dropi.co/integrations/orders/myorders`;

    const result = await axios.post(urlrequest, data.bodyOptions)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  }
  
  // Crea la tr
  async CheckoutTransactionsBepayShippers(data) {
    // RUTA QUEMADA SE PASA EL IDAPP
    const urlrequest = `${this.baseURLPYPws}/bepay/checkout/transactions/shippers/3`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  }
  // Revisa el estado de la tr
  async CheckoutTransactionsStatusBepayhippers(data) {
    // RUTA QUEMADA SE PASA EL IDAPP
    const urlrequest = `${this.baseURLPYPws}/bepay/checkout/transactionStatus/shippers`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  }

  /////////////////////////////////////////////
  //////////////// expirtation token /////////
  ///////////////////////////////////////////
  expiration = async (data) => {
    // One exp Time in ms (seconds)

    var exptime = 1000;
    var time = new Date(data.time);
    // console.log(time);

    var save_date = new Date(time);

    var exp_date = new Date();
    var Result = Math.round(1200 - (exp_date.getTime() - save_date.getTime()) / exptime);

    var Final_Result = Result.toFixed(0);
    // console.log('Number of seconds remaining till logout \n' + Final_Result + 's');
  };
  /////////////////////////////////////////////
  //     Login & PassWord Serveces  ///////////
  ////////////////////////////////////////////

  UserSignInLamp = async (data) => {
    const urlrequest = `${this.baseURLws}/user/lamp/sign/in`;

    const bodyOptions = {};

    const result = await axios
      .post(urlrequest, bodyOptions, { headers: data.headerOptions })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  userSignUp = async (data) => {
    const urlrequest = `${this.baseURLws}/user/sign/up`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  userSignUpWithRole = async (data) => {
    const urlrequest = `${this.baseURLws}/user/sign/upp/role`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  userSignIn = async (data) => {
    const urlrequest = `${this.baseURLws}/user/sign/in`;

    const body = {
      email: data.emaUsuario,
      password: data.password,
    };

    const result = await axios
      .post(urlrequest, body)
      .then((res) => {
        axios.defaults.headers.common = { Authorization: `Bearer ${res.data.tokens.access}` };
        localStorage.setItem('token', res.data.tokens.access);
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  userForgotpass = async (data) => {
    const urlrequest = `${this.baseURLws}/user/forgotpass`;

    const body = {
      email: data.email,
    };

    const result = await axios
      .post(urlrequest, body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  userChangepass = async (data) => {
    const urlrequest = `${this.baseURLws}/user/changepass`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
  /////////////////////////////////////////////
  //     Login & PassWord Serveces   //////////
  ////////////////////////////////////////////

  ////////////////////////////////////////////
  /////       Data Users        /////////////
  ///////////////////////////////////////////
  PostUserBasicInfo = async (data) => {
    const urlrequest = `${this.baseURLws}/userBasicInfo`;
    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  PostUserAppShop = async (data) => {
    const urlrequest = `${this.baseURLws}/user/app/shop`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  PostDeleteRolUser = async (data) => {
    const urlrequest = `${this.baseURLws}/user_roles`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  PostUserListAppShop = async (data) => {
    const urlrequest = `${this.baseURLws}/users_list/app/shop`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  GetusersRoles = async (data) => {
    const urlrequest = `${this.baseURLws}/users_roles/${data.params}`;

    const result = await axios.get(urlrequest).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  getTipos = async () => {
    const urlrequest = `${this.baseURLws}/documentsTypes`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getShops = async (data) => {
    const urlrequest = `${this.baseURLws}/shops/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  GetUrlShop = async (data) => {
    const urlrequest = `${this.baseURLws}/getUrlShop/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getAppbyId = async (data) => {
    const urlrequest = `${this.baseURLws}/app/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getAppConfig = async (data) => {
    const urlrequest = `${this.baseURLws}/appConfig/${data.id}`;

    const result = await axios.get(urlrequest).then((res) => {
      return res;
    }) .catch((err) => {
      return err;
    });
    
    return result;
  };

  getFeatures = async (data) => {
    const urlrequest = `${this.baseURLws}/feauturesAdm/${data.id}`;


    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  feautureEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/feature-enable`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  /////////////////////////////////////////////////
  //////////////// Information //////////////////////
  ///////////////////////////////////////////////
  getListState = async () => {
    const urlrequest = `${this.baseURLws}/GetListState`;
    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getListCountrysBepay = async () => {
    const urlrequest = `${this.baseURLPYPws}/bepay/countries/23`;

    const result = await axios
      .post(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };
  
  getListCitiesBepay = async (data) => {
    const urlrequest = `${this.baseURLPYPws}/bepay/cities/${data.id}/23`;
 
    const result = await axios
    .post(urlrequest)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return result;
};

getListCiuuCodesBepay = async () => {
  const urlrequest = `${this.baseURLPYPws}/bepay/ciiuCodes/23`;

  const result = await axios
    .post(urlrequest)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return result;
};

  getListCities = async (data) => {
    const urlrequest = `${this.baseURLws}/GetListCities/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getInformation = async (data) => {
    const urlrequest = `${this.baseURLws}/information/${data.idApp}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateInformation = async (data) => {
    const urlrequest = `${this.baseURLws}/information`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateNotificationsEmail = async (data) => {
    const urlrequest = `${this.baseURLws}/update-notifications-email`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  /////////////////////////////////////////////////
  //////////////// End Information //////////////////////
  ///////////////////////////////////////////////

  ////////////////////////////////////////////////////
  ///////////////// Brands //////////////////////////
  //////////////////////////////////////////////////
  GetBrands = async (data) => {
    const urlrequest = `${this.baseURLws}/brands/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getSublines = async (data) => {
    const urlrequest = `${this.baseURLws}/line-sublines-shop/${data.id}`;
   
    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getLines = async () => {
    const urlrequest = `${this.baseURLws}/lines`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  }

  getBrandsAdmin = async (data) => {
    const urlrequest = `${this.baseURLws}/brandsAdmin/${data.id}`;
    
    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  createBrandWithImage = async (data) => {
    const urlrequest = `${this.baseURLws}/create-brand-with-image`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  // Refactorizado
  createBrand = async (dataRequest) => {
    try {
      const urlrequest = `${this.baseURLws}/create-brand-with-image`;
      const { data } = await axios.post(urlrequest, dataRequest.body);
      return data;
    } catch (error) {
      throw error;
    }
  };

  brandEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/brandEnable`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getBrandbyId = async (data) => {
    const urlrequest = `${this.baseURLws}/brand/${data.id}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateBrand = async (data) => {
    const urlrequest = `${this.baseURLws}/brand`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateImageBrand = async (data) => {
    const urlrequest = `${this.baseURLws}/updateImageBrand`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };
  /////////////////////////////////////////////////
  //////////////// Ads //////////////////////
  ///////////////////////////////////////////////
  getAds = async (data) => {
    const urlrequest = `${this.baseURLws}/adsAdmin/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  // getAdsNew = async (data1) => {
  //   try {
  //     const urlRequest = `${this.baseURLws}/adsAdmin/${data1.id}`;
  //     const { data } = await axios.get(urlRequest);
  //     return data;
  //   } catch (error) {
  //     console.log(error);
  //     // lanzamos una excepcion y manejamos el err en la ppal (mejor opcion)
  //     // throw error
  //   }
  // };

  getAdsTemplate = async (data) => {
    const urlrequest = `${this.baseURLws}/ads-template/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getAdbyId = async (data) => {
    const urlrequest = `${this.baseURLws}/adAdmin/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getFeaturebyId = async (data) => {
    const urlrequest = `${this.baseURLws}/feature/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  //  REPORTS
  /*  ██    ██ ███████ ███    ██ ████████  █████  ███████ 
      ██    ██ ██      ████   ██    ██    ██   ██ ██      
      ██    ██ █████   ██ ██  ██    ██    ███████ ███████ 
       ██  ██  ██      ██  ██ ██    ██    ██   ██      ██ 
        ████   ███████ ██   ████    ██    ██   ██ ███████  */
  GetDetailSalesReport = async (data) => {
    const urlrequest    = `${this.baseURLws}/GetDetailSalesReport`;
    const result        = await axios.post(urlrequest, data.bodyOptions)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  GetconsolidatedSalesReport = async (data) => {
    const urlrequest    = `${this.baseURLws}/GetconsolidatedSalesReport`;
    const result        = await axios.post(urlrequest, data.bodyOptions)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  GetsalesbyCategoriesReport = async (data) => {
    const urlrequest    = `${this.baseURLws}/GetsalesbyCategoriesReport`;
    const result = await axios.post(urlrequest, data.bodyOptions)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  getSalesbyCityReport = async (data) => {
    const urlrequest    = `${this.baseURLws}/getSalesbyCityReport`;
    const result        = await axios.post(urlrequest, data.bodyOptions)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  getSalesbyProductsReport = async (data) => {
    const urlrequest    = `${this.baseURLws}/getSalesbyProductsReport`;
    const result        = await axios.post(urlrequest, data.bodyOptions)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  GetDailySalesSeries   = async (data) => {
    const urlrequest    = `${this.baseURLws}/get-daily-sales-series`;
    const result = await axios.post(urlrequest, data.bodyOptions)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  GetFinanceReport = async (data) => {
    const urlrequest    = `${this.baseURLws}/GetFinanceReport`;
    const result = await axios.post(urlrequest, data.bodyOptions)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };

  /*   █████  ██████  ██████  ███████ 
      ██   ██ ██   ██ ██   ██ ██      
      ███████ ██████  ██████  ███████ 
      ██   ██ ██      ██           ██ 
      ██   ██ ██      ██      ███████  */
  getAppstemplatetype = async (data) => {
    const urlrequest    = `${this.baseURLws}/get-apps-template-type`;
    const result        = await axios.post(urlrequest, data.bodyOptions)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  getApps_most_view = async () => {
    const urlrequest    = `${this.baseURLws}/get-apps-most-views`;
    const result        = await axios.post(urlrequest)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  getActive_Apps = async (data) => {
    const urlrequest    = `${this.baseURLws}/get-active-apps`;
    const result        = await axios.post(urlrequest, data.bodyOptions)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  getActive_Apps_consolidate = async () => {
    const urlrequest    = `${this.baseURLws}/get-active-apps-consolidate`;
    const result        = await axios.post(urlrequest)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  getActive_Eco_consolidate = async () => {
    const urlrequest    = `${this.baseURLws}/get-active-ecommerce-consolidate`;
    const result        = await axios.post(urlrequest)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  getActive_Market_consolidate = async () => {
    const urlrequest    = `${this.baseURLws}/get-active-marketplaces-consolidate`;
    const result        = await axios.post(urlrequest)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };
  
  getVisitorsApp = async (data) => {
    const urlrequest = `${this.baseURLws}/getVisitorsApp`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  GetLastTrReport = async (data) => {
    const urlrequest = `${this.baseURLws}/GetLastTrReport`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  GetLastMostSales = async (data) => {
    const urlrequest = `${this.baseURLws}/GetLastMostSales`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  GetLastTrs = async (data) => {
    const urlrequest = `${this.baseURLws}/GetLastTrs`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };
  //ENDREPORTS

  updateBannerAd = async (data) => {
    const urlrequest = `${this.baseURLws}/update-banner-ad`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateAd = async (data) => {
    const urlrequest = `${this.baseURLws}/ad`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  createAd = async (data) => {
    const urlrequest = `${this.baseURLws}/ad`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  createFeature = async (data) => {
    const urlrequest = `${this.baseURLws}/feature`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateFeature = async (data) => {
    const urlrequest = `${this.baseURLws}/feature`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  adsEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/adsEnable`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  /////////////////////////////////////////////////
  //////////////// End Ads //////////////////////
  ///////////////////////////////////////////////

  /////////////////////////////////////////////////
  //////////////// Payments //////////////////////
  ///////////////////////////////////////////////
  getPayments = async (data) => {
    const urlrequest = `${this.baseURLws}/payments/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getPaymentbyId = async (data) => {
    const urlrequest = `${this.baseURLws}/payment/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getPaymentsAdmin = async (data) => {
    const urlrequest = `${this.baseURLws}/paymentsAdmin/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  createPaymentMethod = async (data) => {
    const urlrequest = `${this.baseURLws}/create-payment-method`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateLogoPayment = async (data) => {
    const urlrequest = `${this.baseURLws}/update-logo-Payment`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updatePayment = async (data) => {
    const urlrequest = `${this.baseURLws}/payment`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  PostPaymentEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/paymentEnable`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
  /////////////////////////////////////////////////
  //////////////// EndPayments //////////////////////
  ///////////////////////////////////////////////

  /////////////////////////////////////////////////
  //////////////// Shippings //////////////////////
  ///////////////////////////////////////////////
  getlogisticOperatorbyId = async (data) => {
    const urlrequest = `${this.baseURLws}/logisticOperator/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getLogisticOperatorsAdmin = async (data) => {
    const urlrequest = `${this.baseURLws}/logistic-operators-Admin/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  createlogisticOperatorMethod = async (data) => {
    const urlrequest = `${this.baseURLws}/create-logistic-operator`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updatelogisticOperators = async (data) => {
    const urlrequest = `${this.baseURLws}/logisticOperator`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateLogologisticOperators = async (data) => {
    const urlrequest = `${this.baseURLws}/update-logo-Logistic-Operator`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  PostlogisticOperatorsEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/logisticOperatorsEnable`;
 
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  /////////////////////////////////////////////////
  //////////////// End Shippings /////////////////
  ///////////////////////////////////////////////

  /////////////////////////////////////////////////
  //////////////// Notifications /////////////////
  ///////////////////////////////////////////////

  getNotifications = async (data) => {
    const urlrequest = `${this.baseURLws}/notifications`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getNotificationsDetails = async (data) => {
    const urlrequest = `${this.baseURLws}/notifications-details/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateNotificationStatus = async (data) => {
    const urlrequest = `${this.baseURLws}/notifications-status`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateNotificationDetailsStatus = async (data) => {
    const urlrequest = `${this.baseURLws}/notificationsDetails-status`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateNotificationDetailsUnread = async (data) => {
    const urlrequest = `${this.baseURLws}/notificationsDetails-unread`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  /////////////////////////////////////////////////
  //////////////// End Notifications /////////////////
  ///////////////////////////////////////////////

  createApp = async (data) => {
    const urlrequest = `${this.baseURLws}/app`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getApps = async () => {
    const urlrequest = `${this.baseURLws}/apps`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getAppsTemplate = async (data) => {
    const urlrequest = `${this.baseURLws}/apps-template`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateLogoApp = async (data) => {
    const urlrequest = `${this.baseURLws}/update-logo-App`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateFaviconApp = async (data) => {
    const urlrequest = `${this.baseURLws}/update-favicon-App`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateApp = async (data) => {
    const urlrequest = `${this.baseURLws}/app`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateTemplateApp = async (data) => {
    const urlrequest = `${this.baseURLws}/app-template`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateAppConfig = async (data) => {
    const urlrequest = `${this.baseURLws}/appConfig`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  //////////////////////////////////////////////
  //////////// FINAL APP ///////////////////////
  /////////////////////////////////////////////

  getShop = async (data) => {
    const urlrequest = `${this.baseURLws}/shop/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  shopEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/shopEnable`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  shopDelete = async (data) => {
    const urlrequest = `${this.baseURLws}/Shop-delete`;

    const headerOptions = { 'Access-Control-Allow-Origin': '*', };

    const result = await axios.post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getUsuario = async (data) => {
    const urlrequest = `${this.baseURLws}/userinfo/${data.id}`;
    // e386570a-464d-42ca-b4d2-78c7f851160e

    const paramsOptions = {};

    const headerOptions = {
      'Access-Control-Allow-Origin': '*',
    };

    const dataForm = {
      params: paramsOptions,
      headers: headerOptions,
    };

    const result = await axios
      .get(urlrequest, dataForm)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getCategories = async (data) => {
    // En el Frontend solo envio el objeto completo
    // id es idApp
    const urlrequest = `${this.baseURLws}/categories/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getCategoriesSelect = async (data) => {
    // En el Frontend solo envio el objeto completo
    // id es idApp
    const urlrequest = `${this.baseURLws}/categoriesSelect/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getCategoriesAdmin = async (data) => {
    const urlrequest = `${this.baseURLws}/categoriesAdmin/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getCategoriesList = async (data) => {
    // En el Frontend solo envio el objeto completo
    // id es idApp
    const urlrequest = `${this.baseURLws}/categories-list/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getCategory = async (data) => {
    // En el Frontend solo envio el objeto completo
    const urlrequest = `${this.baseURLws}/category/${data.id}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  categoryEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/categoryEnable`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  subcategoryEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/subcategoryEnable`;
 
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getSubcategoriesbyApp = async (data) => {
    const urlrequest = `${this.baseURLws}/subcategories-app/${data.id}`;

    const result = await axios.post(urlrequest).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  getSubcategories = async () => {
    const urlrequest = `${this.baseURLws}/subcategories`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getSubcategory = async (data) => {
    // En el Frontend solo envio el objeto completo
    const urlrequest = `${this.baseURLws}/subcategory/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getSubcategoriesByCategory = async (data) => {
    const urlrequest = `${this.baseURLws}/subcategories/${data.id}`;

    const result = await axios
      .post(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  PostInvoicesByShop = async (data) => {
    const urlrequest = `${this.baseURLws}/invoices-shop`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  PostInvoicesStatus = async (data) => {
    const urlrequest = `${this.baseURLws}/invoices-status`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateSubcategory = async (data) => {
    const urlrequest = `${this.baseURLws}/subcategory`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateCategory = async (data) => {
    const urlrequest = `${this.baseURLws}/category`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getUserApps = async (data) => {
    const urlrequest = `${this.baseURLws}/app-user/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getMenusByRol = async (data) => {
    const urlrequest = `${this.baseURLws}/menus-rol/${data.idRol}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getMenus = async () => {
    const urlrequest = `${this.baseURLws}/menus`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getRoles = async () => {
    const urlrequest = `${this.baseURLws}/roles`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  AddMenu = async (data) => {
    const urlrequest = `${this.baseURLws}/addMenu`;

    const result = await axios.post(urlrequest, data.bodyOptions).then((res) => {
      return res;
    }).catch((err) => {
      return err;
    });

    return result;
  };

  DeleteMenu = async (data) => {
    const urlrequest = `${this.baseURLws}/deleteMenu`;

    const result = await axios.post(urlrequest, data.bodyOptions).then((res) => {
      return res;
    }).catch((err) => {
      return err;
    });

    return result;
  };

  getMenu = async (data) => {
    const urlrequest = `${this.baseURLws}/menu/${data.idMenu}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getSubMenus = async (data) => {
    const urlrequest = `${this.baseURLws}/subMenu/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  ////////////////////////////////////////////
  /////      End  Data Users     /////////////
  ///////////////////////////////////////////

  ////////////////////////////////////////////
  /////       Data Products        /////////////
  ///////////////////////////////////////////

  getThumbnail = async (data) => {
    const urlrequest = `${this.baseURLws}/thumbnail/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  productEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/productEnable`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getImagesByProduct = async (data) => {
    const urlrequest = `${this.baseURLws}/images-product/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  PostimageEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/image-enable`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
  
  

  createProduct = async (data) => {
    const urlrequest = `${this.baseURLws}/product`;

    const result = await axios.post(urlrequest, data.bodyOptions).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  createMasiveProducts = async (data) => {
    const urlrequest = `${this.baseURLPYPws}/services/createMasiveProducts`;

    const result = await axios.post(urlrequest, data.body).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  createMasivePins = async (data) => {
    const urlrequest = `${this.baseURLPYPws}/services/createProductPinsMassive`;

    const result = await axios.post(urlrequest, data.body).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  detailsMasiveProducts = async (data) => {
    const urlrequest = `${this.baseURLws}/details-masive-products`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  createProductDimensions = async (data) => {
    const urlrequest = `${this.baseURLws}/productDimensions`;

    const bodyOptions = {
      idProduct: data.idProduct,
      weight: data.weight,
      width: data.width,
      height: data.height,
      length: data.length,
    };

    const result = await axios
      .post(urlrequest, bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateProductDimensions = async (data) => {
    const urlrequest = `${this.baseURLws}/productDimensions`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateProductVariants = async (data) => {
    const urlrequest = `${this.baseURLws}/products-variants`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  createProductVariants = async (data) => {
    const urlrequest = `${this.baseURLws}/products-variants`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  createProductCategories = async (data) => {
    const urlrequest = `${this.baseURLws}/productsCategories`;

    const bodyOptions = {
      idProduct: data.idProduct,
      idCategory: data.idCategory,
      idSubcategory: data.idSubcategory,
    };

    const result = await axios
      .post(urlrequest, bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateProductCategories = async (data) => {
    const urlrequest = `${this.baseURLws}/productsCategories`;

    const bodyOptions = {
      id: data.id,
      idProduct: data.idProduct,
      idCategory: data.idCategory,
      idSubcategory: data.idSubcategory,
    };

    const result = await axios
      .put(urlrequest, bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateProductBrand = async (data) => {
    const urlrequest = `${this.baseURLws}/productBrand`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateProductNameBrand = async (data) => {
    const urlrequest = `${this.baseURLws}/productBrandName`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  createProductBrand = async (data) => {
    const urlrequest = `${this.baseURLws}/productBrand`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateProductStock = async (data) => {
    const urlrequest = `${this.baseURLws}/product-stock`;

    const bodyOptions = {
      id: data.id,
      stock: data.stock,
      low_limit: data.low_limit,
    };

    const result = await axios
      .put(urlrequest, bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateOfferPrice = async (data) => {
    const urlrequest = `${this.baseURLws}/product-offer-price`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getProductCategories = async (data) => {
    const urlrequest = `${this.baseURLws}/products-categories/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateProduct = async (data) => {
    const urlrequest = `${this.baseURLws}/product`;

    const result = await axios.put(urlrequest, data.bodyOptions).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  createThumbnail = async (data) => {
    const urlrequest = `${this.baseURLws}/thumbnail`;

    const result = await axios.post(urlrequest, data.body).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
    return result;
  };

  createSubcategorywithImage = async (data) => {
    const urlrequest = `${this.baseURLws}/create-subcategory-with-image`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  createCategoryWithImage = async (data) => {
    const urlrequest = `${this.baseURLws}/create-category-with-image`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateImageSubcategory = async (data) => {
    const urlrequest = `${this.baseURLws}/updateImageSubcategory`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateImageCategory = async (data) => {
    const urlrequest = `${this.baseURLws}/updateImageCategory`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateThumbnail = async (data) => {
    const urlrequest = `${this.baseURLws}/update-thumbnail`;

    const result = await axios.post(urlrequest, data.body).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  createImages = async (data) => {
    const urlrequest = `${this.baseURLws}/images`;

    // Loop through formData
    // for (const [key, value] of data.body) {
    //   console.log("llave valor fromData->", key, value);
    // }

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateImages = async (data) => {
    const urlrequest = `${this.baseURLws}/update-images`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };


  // Busco la tienda a vincular en el centro comercial virtual
  // Cristian Hoyos Velandia
  // V 1.0.0
  fetchingShop = async (data) => {
    // URL de la solicitud.
    const urlrequest = `${this.baseURLws}/fetching-shop-mp`;

    // Realiza una solicitud POST utilizando Axios.
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res; // Retorna la respuesta exitosa.
      })
      .catch((err) => {
        return err; // Retorna el error en caso de fallo.
      });

    return result; // Retorna el resultado de la solicitud.
  };

  // Agrego la tienda al MP por medio del shop category
  // Cristian Hoyos Velandia
  // V 1.0.0
  addShopCategory = async (data) => {
    // URL de la solicitud.
    const urlrequest = `${this.baseURLws}/add-shop-category-mp`;

    // Realiza una solicitud POST utilizando Axios.
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res; // Retorna la respuesta exitosa.
      })
      .catch((err) => {
        return err; // Retorna el error en caso de fallo.
      });

    return result; // Retorna el resultado de la solicitud.
  };

  // Agrego la tienda al MP por medio del shop category
  // Cristian Hoyos Velandia
  // V 1.0.0
  updateShopCategory = async (data) => {
    // URL de la solicitud.
    const urlrequest = `${this.baseURLws}/update-shop-category-mp`;

    // Realiza una solicitud POST utilizando Axios.
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res; // Retorna la respuesta exitosa.
      })
      .catch((err) => {
        return err; // Retorna el error en caso de fallo.
      });

    return result; // Retorna el resultado de la solicitud.
  };

  createShop = async (data) => {
    const urlrequest = `${this.baseURLws}/shop`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateLogoShop = async (data) => {
    const urlrequest = `${this.baseURLws}/update-logo-shop`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  createUserApp = async (data) => {
    const urlrequest = `${this.baseURLws}/userApp`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateShop = async (data) => {
    const urlrequest = `${this.baseURLws}/shop`;

    const bodyOptions = {
      id: data.id,
      nameShop: data.nameShop,
      document: data.document,
      description: data.description,
      dv: data.dv,
      idApp: data.idApp,
      address: data.address,
      phone: data.phone,
      email: data.email,
      status: data.status,
    };

    const result = await axios
      .put(urlrequest, bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getShopsProducts = async (data) => {
    const urlrequest = `${this.baseURLws}/shops-products/${data.id}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getShopsProductsAdmin = async (data) => {
    const urlrequest = `${this.baseURLws}/shops-products-admin/${data.id}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getShopsDigitalProductsAdmin = async (data) => {
    const urlrequest = `${this.baseURLws}/shops-digital-products-admin/${data.id}`;

    const result = await axios.post(urlrequest, data.bodyOptions).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  getShopsDPwithPinsAdmin = async (data) => {
    const urlrequest = `${this.baseURLws}/shops-dp-with-pins-admin/${data.id}`;

    const result = await axios.post(urlrequest, data.bodyOptions).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  getAllProductGrouped = async (data) => {
    const urlrequest = `${this.baseURLws}/product-grouped-shop/${data.id}`;

    const result = await axios.get(urlrequest).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  deleteProductGrouped = async (data) => {
    const urlrequest = `${this.baseURLws}/delete-product-grouped`;

    const result = await axios.post(urlrequest, data.bodyOptions).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  updateProductGrouped = async (data) => {
    const urlrequest = `${this.baseURLws}/product-grouped`;

    const result = await axios.put(urlrequest, data.bodyOptions).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  createProductGrouped = async (data) => {
    const urlrequest = `${this.baseURLws}/product-grouped`;

    const result = await axios.post(urlrequest, data.bodyOptions).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };



  

  getDigitalPinsbyIdProduct = async (data) => {
    const urlrequest = `${this.baseURLws}/get-digital-pins-by-id-product`;

    const result = await axios.post(urlrequest, data.bodyOptions).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  updateDigitalPinbyIdProduct = async (data) => {
    const urlrequest = `${this.baseURLws}/update-digital-pins-by-id-product`;
  
    const result = await axios.post(urlrequest, data.bodyOptions).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  expiredAllDigitalPinsbyIdProductandIdShop = async (data) => {
    const urlrequest = `${this.baseURLws}/expired-digital-pins-by-id-product`;

    const result = await axios.post(urlrequest, data.bodyOptions).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  getPurchasePin = async (data) => {
    const urlrequest = `${this.baseURLws}/pin/get-purchase-pin/${data.id}`;
  
    const result = await axios.post(urlrequest, null).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };


  getProductsStock = async (data) => {
    const urlrequest = `${this.baseURLws}/products-stock/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getProductsDimensions = async (data) => {
    const urlrequest = `${this.baseURLws}/products-dimensions/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getAllProductsWithThumbnail = async (data) => {
    const urlrequest = `${this.baseURLws}/products-all-with-thumbnail/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  GetAllProductsBrands = async (data) => {
    const urlrequest = `${this.baseURLws}/products-all-brand/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  GetProductVariantsbyId = async (data) => {
    const urlrequest = `${this.baseURLws}/product-variants/${data.id}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getAllProductsVariants = async (data) => {
    const urlrequest = `${this.baseURLws}/products-all-variants/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getTypeVariants = async () => {

    try {
      const urlrequest = `${this.baseURLws}/getTypeVariants`;
      const { data } = await axios.get(urlrequest);
      return data;
    } catch (error) {
      throw error;
    }
  };

  getAllProductsMasive = async (data) => {
    const urlrequest = `${this.baseURLws}/products-all-masive/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getProduct = async (data) => {
    const urlrequest = `${this.baseURLws}/product/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getProductFull = async (data) => {
    const urlrequest = `${this.baseURLws}/product-full/${data.idProduct}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getProductVariants = async (data) => {
    const urlrequest = `${this.baseURLws}/product-variants/${data.idProduct}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  ////////////////////////////////////////////
  /////      End  Data Products     /////////////
  ///////////////////////////////////////////

  ////////////////////////////
  //// Orders ///////
  //////////////////////////
  getOrdersDispatchedbyIdShop = async (data) => {
    const urlrequest = `${this.baseURLws}/order-shop-dispatched/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };
  getOrdersbyIdShop = async (data) => {
    const urlrequest = `${this.baseURLws}/order-shop/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  postInvoicepayShippersElp = async (data) => {
    const urlrequest = `${this.baseURLws}/invoice-pay-shippers-elp`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  PostInvoicepayShippersBepay = async (data) => {
    const urlrequest = `${this.baseURLws}/invoice-pay-shippers-bepay`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  getOrdersByIdandIdShop = async (data) => {
    const urlrequest = `${this.baseURLws}/order-shop-id`;
 
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  
  GetProductsDimensionFront = async (data) => {
    const urlrequest = `${this.baseURLws}/products-dimensions-front/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  UpdateNumReceiptbyidOrder = async (data) => {
    const urlrequest = `${this.baseURLws}/update-num-receipt-order`;
    // const paramsOptions = {};

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };
  UpdateGuide_LinkbyidOrder = async (data) => {
    const urlrequest = `${this.baseURLws}/update-guide-link`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  
  // Función para obtener el total de tiendas.
  GetTotalStoresMP = async (data) => {
    // URL de la solicitud.
    const urlrequest = `${this.baseURLws}/get-total-stores-mp`;

    // Realiza una solicitud POST utilizando Axios.
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res; // Retorna la respuesta exitosa.
      })
      .catch((err) => {
        return err; // Retorna el error en caso de fallo.
      });

    return result; // Retorna el resultado de la solicitud.
  };

  // Función para obtener el total de ventas.
  GetTotalSalesMP = async (data) => {
    // URL de la solicitud.
    const urlrequest = `${this.baseURLws}/get-total-sales-mp`;

    // Realiza una solicitud POST utilizando Axios.
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res; // Retorna la respuesta exitosa.
      })
      .catch((err) => {
        return err; // Retorna el error en caso de fallo.
      });

    return result; // Retorna el resultado de la solicitud.
  };

  // Función para obtener las últimas tiendas.
  GetlastStores = async (data) => {
    // URL de la solicitud.
    const urlrequest = `${this.baseURLws}/get-last-stores-mp`;

    // Realiza una solicitud POST utilizando Axios.
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res; // Retorna la respuesta exitosa.
      })
      .catch((err) => {
        return err; // Retorna el error en caso de fallo.
      });

    return result; // Retorna el resultado de la solicitud.
  };

  // Función para obtener las tiendas con mas ventas.
  GetMostSalesMP = async (data) => {
    // URL de la solicitud.
    const urlrequest = `${this.baseURLws}/get-most-sales-mp`;

    // Realiza una solicitud POST utilizando Axios.
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res; // Retorna la respuesta exitosa.
      })
      .catch((err) => {
        return err; // Retorna el error en caso de fallo.
      });

    return result; // Retorna el resultado de la solicitud.
  };

  getListState = async (data) => {
    const urlrequest = `${this.baseURLws}/GetListState`;
    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };
  
  getDetailedSales = async (data) => {
    const urlrequest = `${this.baseURLws}/detailed-sales`;

    const bodyOptions = {
      idShop: data.idShop,
      from: data.from,
      until: data.until,
    };

    const result = await axios
      .post(urlrequest, bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getCommerces = async (data) => {
    const urlrequest = `${this.baseURLws}/comercios`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getCommerce = async (data) => {
    const urlrequest = `${this.baseURLws}/comercios/${data.params}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  ///////////////////////////////////////////////////////
  /////////// ORGANIZATIONAL ///////////////////////////

  getOrganizationalAll = async (data) => {
    const urlrequest = `${this.baseURLws}/organizational-app-admin/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  updateOrganizational = async (data) => {
    const urlrequest = `${this.baseURLws}/organizational`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  organizationalEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/organizationalEnable`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  createOrganizational = async (data) => {
    const urlrequest = `${this.baseURLws}/organizational`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  /////////////////////////////////////////////////////
  ///////////// FIN ORGANIZATIONAL ////////////////////

  ///////////////////////////////////////////////////////
  ///////// BRIEF //////////////////////////////////////

  getBriefs = async () => {
    const urlrequest = `${this.baseURLws}/briefs`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getBriefById = async (data) => {
    const urlrequest = `${this.baseURLws}/brief/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  createBrief = async (data) => {
    const urlrequest = `${this.baseURLws}/create-brief`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateBrief = async (data) => {
    const urlrequest = `${this.baseURLws}/brief`;

    const result = await axios
      .put(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  /////////////////////////////////////////////////////
  ///////////// FIN BREIF /////////////////////////////

  ////////// ONBOARDING ////////////////////////////////

  getBanksBePay = async() => {
    try {
      const urlrequest = `${this.baseURLws}/banks-bepay`;
      const { data } = await axios.get(urlrequest);
      return data;
    } catch (error) {
      throw error;
    }
  }

  getOnboardingByIdApp = async (body) => {
    try {
      const urlrequest = `${this.baseURLws}/onboarding/${body.id}`;
      const { data } = await axios.post(urlrequest, body);
      return data;
    } catch (error) {
      throw error;
    }
  };

  getKeysByIdApp = async (body) => {
    try {
      const urlrequest = `${this.baseURLws}/payments/get-keys-mi-plante`;
      const { data } = await axios.post(urlrequest, body);
      return data;
    } catch (error) {
      throw error;
    }
  };

  createHolder = async (body) => {
    try {
      const urlrequest = `${this.baseURLws}/create-holder`;
  
      const { data } = await axios.post(urlrequest, body);
      return data;
    } catch (error) {
      throw error;
    }
  };

  updateBusinessReference = async (body) => {
    try {
      const urlrequest = `${this.baseURLws}/update-business-reference`;
      const { data } = await axios.put(urlrequest, body);
      return data;
    } catch (error) {
      throw error;
    }
  };

  updateBankingInformation = async (body) => {
    try {
      const urlrequest = `${this.baseURLws}/update-banking-information`;
      const { data } = await axios.put(urlrequest, body);
      return data;
    } catch (error) {
      throw error;
    }
  };

  // updateFiles = async (dataRequest) => {
  //   try {
  //     const urlrequest = `${this.baseURLws}/update-files`;
  //     const headers = {
  //       'Content-Type': 'multipart/form-data',
  //     };
  //     const { data } = await axios.post(urlrequest, dataRequest.body, { headers: headers });
  //     return data;
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  updateFiles = async (data) => {
    const urlrequest = `${this.baseURLws}/update-files`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updatePhotoSignature = async (dataRequest) => {
    try {
      const urlrequest = `${this.baseURLws}/update-photo-signature`;
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const { data } = await axios.post(urlrequest, dataRequest.body, { headers: headers });
      return data;
    } catch (error) {
      throw error;
    }
  };

  // getAdsNew = async (data1) => {
  //   try {
  //     const urlRequest = `${this.baseURLws}/adsAdmin/${data1.id}`;
  //     const { data } = await axios.get(urlRequest);
  //     return data;
  //   } catch (error) {
  //     console.log(error);
  //     // lanzamos una excepcion y manejamos el err en la ppal (mejor opcion)
  //     // throw error
  //   }
  // };

  ///////// FIN ONBOARDING //////////////////////////////

  ///////////////////////////////////////////////////////
  ///////// FAQs //////////////////////////////////////

  getFAQs = async (data) => {
    const urlrequest = `${this.baseURLws}/faqs/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getFAQ = async (data) => {
    const urlrequest = `${this.baseURLws}/faq/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  createFAQ = async (data) => {
    const urlrequest = `${this.baseURLws}/faq`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  updateFAQ = async (data) => {
    const urlrequest = `${this.baseURLws}/faq`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  /////////////////////////////////////////////////////
  ///////////// FIN FAQs /////////////////////////////

  /////////////////////////////////////////////////////
  ///////////// PQRS /////////////////////////////

  getPQRS = async (data) => {
    const urlrequest = `${this.baseURLws}/pqrss/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getStatusPQRS = async (data) => {
    const urlrequest = `${this.baseURLws}/status-pqrs`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  CreatePQRSResponse = async (data) => {
    const urlrequest = `${this.baseURLws}/pqrsResponse`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getPQRSById = async (data) => {
    const urlrequest = `${this.baseURLws}/pqrs/${data.id}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
  getPQRSResponse = async (data) => {
    const urlrequest = `${this.baseURLws}/pqrsResponse/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  /////////////////////////////////////////////////////
  ///////////// FIN PQRS /////////////////////////////

  /////////////////////////////////////////////////////////
  /////////// GET CIUDADES ///////////////////////////////
  ///////////////////////////////////////////////////////
  getCiudades = async (data) => {
    const urlrequest = `${this.baseURLws}/getCiudades`;

    const body = {
      codUsuario: data.codUsuario,
    };

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  // -------------------------------- REVIEWS ----------------------------------------
  getReviewsByShop = async (data) => {
    const urlrequest = `${this.baseURLws}/reviews-shop/${data.idShop}`;

    const body = {
      codUsuario: data.codUsuario,
    };

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  reviewsEnable = async (data) => {
    const urlrequest = `${this.baseURLws}/review-enable`;
 
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  //----------------------------------------------------------------------------------------
  //---------------------------------------------  Sistecredito -----------------------------------
  //--------------------------------------------------------------------------------------
  GetContractPMSistecredito = async (data) => {
    const urlrequest = `${this.baseURLws}/get-contract-pm-sistecredito/${data.id}`;

    const result = await axios.get(urlrequest).then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    return result;
  };

  WaitKeysSistecredito = async (data) => {
    const urlrequest = `${this.baseURLws}/wait-keys-sistecredito`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  GetKeysSistecredito= async (data) => {
    const urlrequest = `${this.baseURLws}/get-keys-sistecredito/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
  
  AcceptContractSistecredito = async (data) => {
    const urlrequest = `${this.baseURLws}/accept-contract-sistecredito/${data.id}`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  
  PostKeys_Sistecredito = async (data) => {
    const urlrequest = `${this.baseURLws}/post-keys-sistecredito/${data.id}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  //----------------------------------------------------------------------------------------
  //---------------------------------------------  Bepay -----------------------------------
  //--------------------------------------------------------------------------------------
  Getcontracts_paymentMethodsBepay = async (data) => {
    const urlrequest = `${this.baseURLws}/GetcontractsPaymentMethodsBepay/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  AcceptContractBepay = async (data) => {
    const urlrequest = `${this.baseURLws}/acceptContractBepay/${data.id}`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  sendContractBepay = async (data) => {
    const urlrequest = `${this.baseURLws}/send-contract-bepay`;

    const result = await axios
      .post(urlrequest, data.body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };
  //----------------------------------------------------------------------------------------
  //---------------------------------------------  Mi Plante -----------------------------------
  //--------------------------------------------------------------------------------------
  Getcontracts_paymentMethodsMiplante = async (data) => {
    const urlrequest = `${this.baseURLws}/GetcontractsPaymentMethodsMiplante/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  AcceptContractMiplante = async (data) => {
    const urlrequest = `${this.baseURLws}/acceptContractMiplante/${data.id}`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  sendContractMiplante = async (data) => {
    console.log('esto viene en la data send contract mi plante', data);
    const urlrequest = `${this.baseURLws}/payments/save-key-mi-plante`;

    const result = await axios
      .post(urlrequest, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  verifyKeysMiPlante = async (data) => {
    const urlRequest = `${this.baseURLws}/payments/verify-keys-mi-plante`;

    const result = await axios
      .post(urlRequest, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  }
  //----------------------------------------------------------------------------------------
  //---------------------------------------------  ELP -----------------------------------
  //--------------------------------------------------------------------------------------
  Getcontracts_paymentMethodsELP = async (data) => {
    const urlrequest = `${this.baseURLws}/GetcontractsPaymentMethodsELP/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
  AcceptContractELP = async (data) => {
    const urlrequest = `${this.baseURLws}/AcceptContractELP/${data.id}`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
  //---------------------------------------------  EPAYCO -----------------------------------
  //--------------------------------------------------------------------------------------
  getContractPaymentMethodEpayco = async (data) => {
    const urlrequest = `${this.baseURLws}/GetcontractsPaymentMethodsEpayco/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getKeysEpayco = async (data) => {
    const urlrequest = `${this.baseURLws}/getKeysEpayco/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  postKeysEpayco = async (data) => {
    const urlrequest = `${this.baseURLws}/postKeysEpayco/${data.id}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  AcceptContractEpayco = async (data) => {
    const urlrequest = `${this.baseURLws}/AcceptContractEpayco/${data.id}`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

    //---------------------------------------------  MIPLANTE -----------------------------------
  //--------------------------------------------------------------------------------------
  getContractPaymentMethodMiPlante = async (data) => {
    const urlrequest = `${this.baseURLws}/GetcontractsPaymentMethodsMiPlante/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  getKeysMiPlante = async (data) => {
    const urlrequest = `${this.baseURLws}/getKeysMiPlante/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  postKeysMiPlante = async (data) => {
    const urlrequest = `${this.baseURLws}/postKeysMiPlante/${data.id}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  AcceptContractMiPlante = async (data) => {
    const urlrequest = `${this.baseURLws}/AcceptContractMiPlante/${data.id}`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
  //----------------------------------------------------------------------------------------
  //---------------------------------------------SHIPPERS-----------------------------------
  //----------------------------------------------------------------------------------------
  AcceptContractShippers = async (data) => {
    const urlrequest = `${this.baseURLws}/acceptContractShippers/${data.id}`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  GetShippersStoreLocation = async (data) => {
    const urlrequest = `${this.baseURLws}/GetShippersStoreLocation/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
  //----------------------------------------------------------------------------------------
  //---------------------------------------------EnvioClick-----------------------------------
  //----------------------------------------------------------------------------------------
  AcceptContractEnvioclick = async (data) => {
    // el endpoint al que apunta es el mismo que el de shippers debido a que no cambia la lógica
    // solo cambia el proveedor de la tecnología
    const urlrequest = `${this.baseURLws}/acceptContractShippers/${data.id}`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  GetEnvioclickStoreLocation = async (data) => {
    // Función que ahora funciona también para EnvioClick
    const urlrequest = `${this.baseURLws}/GetShippersStoreLocation/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  createShipmentFromEnvioclick = async (data) => {
    const urlrequest = `${this.baseURLPYPws}/envioclick/shipment`;
    // return {
    //       error: false,
    //       status: 200,
    //       data: {
    //       res: {
    //         status: "OK",
    //         status_codes: [
    //           200
    //         ],
    //         status_messages: [
    //           {
    //             request: "Request processed."
    //           }
    //         ],
    //         data: {
    //         idRate: 10998508,
    //         myShipmentReference: "Orden 5649",
    //         requestPickup: false,
    //         insurance: true,
    //         packages: [
    //           {
    //             weight: 1,
    //             height: 10,
    //             width: 5,
    //             length: 15
    //           }
    //         ],
    //         origin: {
    //           company: "company",
    //           firstName: "Santiago",
    //           lastName: "Muñoz",
    //           email: "santiago@test.com",
    //           phone: "3227684041",
    //           suburb: "Gaitan",
    //           crossStreet: "Calle 39c #10-69-NA",
    //           reference: "Casa puertas negras",
    //           address: "Calle 98 62-37",
    //           daneCode: "11001000"
    //         },
    //         destination: {
    //           company: "NA",
    //           firstName: "Fabian Eduardo",
    //           lastName: "Muñoz Rodriguez",
    //           email: "fabian.munoz@test.com",
    //           phone: "3112177117",
    //           suburb: "Capellania",
    //           crossStreet: "Apto 316",
    //           reference: "N/A",
    //           address: "Carrera 46 # 93 - 45",
    //           daneCode: "11001000"
    //         },
    //         description: "descripcion",
    //         contentValue: 12000,
    //         url: "https://s3-us-west-2.amazonaws.com/envioclickpro-util/sandbox_guide.pdf",
    //         tracker: "123SANDBOX456TRACK789NUMBER",
    //         idOrder: 132456
    //       }
    //     }
    //   }
    // }
    
    console.log('data.bodyOptions', data.bodyOptions);
    console.log('url request', urlrequest);
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  // generateGuidesFromShippers = async (data) => {
  //   const urlrequest = `${this.baseURLPYPws}/shipments/generate-guides/${data.idApp}/${data.idShop}`;

  //   const result = await axios
  //     .post(urlrequest, data.bodyOptions)
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });

  //   return result;
  // };

  updateStoreLocationEnvioclickFromContento = async (data) => {
    const urlrequest = `${this.baseURLws}/update-store-locations-shippers-from-dokma`;
  
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
  
  ///////////////////////////////////////////////////////////
  //   Agile Dispatch
  ///////////////////////////////////////////////////////////
  GetDespachoAgilStatus = async (data) => {
    const urlrequest = `${this.baseURLws}/get-despacho-agil-status/${data.id}`;

    const result = await axios
      .get(urlrequest)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  AcceptAgileDispatch = async (data) => {
    const urlrequest = `${this.baseURLws}/accept-despacho-agil/${data.id}`;
 
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error('Error en la solicitud:', err);
        return err;
      });

    return result;
  };

  createShipmentFromShippers = async (data) => {
    const urlrequest = `${this.baseURLPYPws}/shipments/shipments/${data.idApp}/${data.idShop}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  generateGuidesFromShippers = async (data) => {
    const urlrequest = `${this.baseURLPYPws}/shipments/generate-guides/${data.idApp}/${data.idShop}`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };

  PostStoreLocationShippers = async (data) => {
    const urlrequest = `${this.baseURLPYPws}/shipments/store-locations`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error('Error en la solicitud:', err);
        return err;
      });

    return result;
  };

  CreateStoreShippers = async (data) => {
    const urlrequest = `${this.baseURLPYPws}/shipments/stores`;

    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error('Error en la solicitud:', err);
        return err;
      });

    return result;
  };

  PutStoreLocationShippers = async (data) => {
    const urlrequest = `${this.baseURLPYPws}/shipments/store-locations/${data.id}/${data.idApp}/${data.idShop}`;

    const result = await axios
      .put(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error('Error en la solicitud:', err);
        return err;
      });

    return result;
  };

  updateStoreLocationShippersFromContento = async (data) => {
    const urlrequest = `${this.baseURLws}/update-store-locations-shippers-from-dokma`;
  
    const result = await axios
      .post(urlrequest, data.bodyOptions)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  };
}

export default AxiosCalls;
