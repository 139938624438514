<template>
  <!-- Languages SELECT -->
  <div class="dropdown nav-item language-dropdown btn-group">
    <a href="javascript:;" id="ddllang" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
      <img v-if="selectedLang" :src="require(`@/assets/images/flags/${selectedLang.code}.png`)" class="flag-width" alt="flag" />
    </a>
    <ul class="dropdown-menu" aria-labelledby="ddllang">
      <perfect-scrollbar>
        <li v-for="item in countryList" :key="item.code">
          <a href="javascript:;" class="dropdown-item d-flex align-items-center" :class="{ active: $i18n.locale === item.code }" @click.prevent="changeLanguage(item)">
            <img :src="require(`@/assets/images/flags/${item.code}.png`)" class="flag-width" alt="" /> <span>{{ item.name }}</span>
          </a>
        </li>
      </perfect-scrollbar>
    </ul>
  </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useI18n } from 'vue-i18n';

    const i18n = useI18n();
    const selectedLang = ref(null);
    const countryList = ref(store.getters.countryList);
    
    onMounted(() => {
      selectedLang.value = window.$appSetting.toggleLanguage();
    });

    const changeLanguage = (item) => {
      console.log(item)
      selectedLang.value = item;
      window.$appSetting.toggleLanguage(item);
      i18n.locale.value = item.code;
    };
</script>
