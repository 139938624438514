<template>
  <br />
  <li class="menu">
    <router-link to="/" class="dropdown-toggle" @click="toggleMobileMenu">
      <!-- <div class="">
        <div class="">
          <i data-feather="activity"></i>
          <span>{{ $t('Data') }}</span>
        </div>
      </div> -->
      <div class="">
        <fa :icon="['fas', 'layer-group']" />
        <span>{{ $t('Data') }}</span>
      </div>
    </router-link>
  </li>

  <li :key="user.idApp" v-if="user.idRol != 2 && user.idRol != 6 && user.idRol != 7 && user.idRol != 10" class="menu">
    <a @click="gotoShop()" class="dropdown-toggle" target="_blank">
      <div class="">
        <fa :icon="['fas', 'share']" />
        <!-- <fa  v-if="store.getters.getUser?.appType == 0" :icon="['fas', 'store']" /> -->
        <span v-if="store.getters.getUser?.appType == 0"> {{ $t('Go to Shop') }} </span>
        <span v-if="store.getters.getUser?.appType == 1" style="font-size: 11px;"> Ver Centro Comercial </span>
      </div>
    </a>
  </li>

  <br />

  <!-- New menu -->
  <menuList v-for="(menu, index) in menus" :key="index" :menu="menu" :sub-menus="subMenus.filter((item) => item.idMenu == menu.id)" />

  <br />
  <span>{{ $t('Others') }}</span>

  <li class="menu">
    <router-link to="/Profile" class="dropdown-toggle" @click="toggleMobileMenu">
      <div class="">
        <fa :icon="['fas', 'user-circle']" />
        <span>{{ $t('Profile') }}</span>
      </div>
    </router-link>
  </li>

  <li class="menu">
    <a class="dropdown-toggle" data-bs-toggle="modal" data-bs-target="#modalLogout">
      <div class="">
        <fa :icon="['fas', 'sign-out-alt']" class="feather feather-airplay"> </fa>
        <span>{{ $t('Log Out') }}</span>
      </div>
    </a>
  </li>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import menuList from './menuList.vue';
  //   import Arrow from '@/components/layout/components/arrow.vue';

  //Store
  const store = useStore();
  const user = store.getters.getUser;

  //Referencias
  const menus = ref([]);
  const menusref = ref([]);
  const subMenus = ref([]);
  const menusRoles = ref([]);
  const menuAux = ref([]);

  //onMounted
  onMounted(() => {
    getMenus();
    getMenusByRol();
    // gotoShop();
  });

  function getMenus() {
    var GetMenuResponse = axiosWS.getMenus();
    GetMenuResponse.then((res) => {
      if (res.status == 200) {
        const { data } = res;
        menuAux.value = data.menus;
        menuAux.value.forEach((e) => {
          e.idMenu == 0 ? menusref.value.push(e) : subMenus.value.push(e);
        });
      }
      // console.log("MenuAuxiliar--->", menuAux.value)
      // console.log("menusref--->", menusref.value)
      // console.log("subMenus--->", subMenus.value)
    });
  }

  function getMenusByRol() {
    // console.log('user-menu', user);
    var GetMenuRolResponse = axiosWS.getMenusByRol(user);

    GetMenuRolResponse.then((res) => {
      if (res.status == 200) {
        const { data } = res;
        // console.log("Respuesta del menus rol---<", data)
        menusRoles.value = data.menus;
        store.dispatch('setMenusRol', menusRoles.value);
        setTimeout(() => {
          sortMenusUser();
        }, 500);
      } else {
      }
    });
  }

  function sortMenusUser() {
    menuAux.value = [];

    for (let i = 0; i < menusRoles.value.length; i++) {
      if (menusRoles.value[i]) {
        //busqueme un item
        const item = menusref.value.find((item) => item.id == menusRoles.value[i].idMenu);
        if (item) {
          menuAux.value.push(item);
        }
      }
    }
    
    var k, i, aux;
    for (k = 1; k < menuAux.value.length; k++) {
      for (i = 0; i < menuAux.value.length - k; i++) {
        if (menuAux.value[i].numOrder > menuAux.value[i + 1].numOrder) {
          aux = menuAux.value[i];
          menuAux.value[i] = menuAux.value[i + 1];
          menuAux.value[i + 1] = aux;
        }
      }
    }

    menus.value = menuAux.value;
    store.dispatch('setMenu', menus.value);
    store.dispatch('setSubMenu', subMenus.value);
  }

  const toggleMobileMenu = () => {
    if (window.innerWidth < 991) {
      store.commit('toggleSideBar', !store.state.is_show_sidebar);
    }
  };

  const gotoShop = () => {
    const datar = {
      id: user.idApp,
    };
    // console.log(datar)
    var GetUrlShopresponse = axiosWS.GetUrlShop(datar);
    GetUrlShopresponse.then((res) => {
      if (res.status == 200) {
        const { data } = res;
        if (data.error == false) {
          const urlWithHttps = `https://${data.UrlDomain}`;
          // console.log('urlDomain con https', urlWithHttps);
          window.open(urlWithHttps, '_blank');
          // window.open(data.UrlDomain, '_blank');
        }
      }
    });
  };
</script>
