import i18n from '../../i18n';

const mutations = {
  // Mutations from template
  setLayout(state, payload) {
    state.layout = payload;
  },
  toggleSideBar(state, value) {
    state.is_show_sidebar = value;
  },
  toggleSearch(state, value) {
    state.is_show_search = value;
  },
  toggleLocale(state, value) {
    value = value || 'es';
    i18n.global.locale = value;
    localStorage.setItem('i18n_locale', value);
    state.locale = value;
  },
  toggleDarkMode(state, value) {
    //light|dark
    value = value || 'light';
    localStorage.setItem('dark_mode', value);
    state.dark_mode = value;
    if (value == 'light') {
      state.is_dark_mode = false;
    } else if (value == 'dark') {
      state.is_dark_mode = true;
    }

    if (state.is_dark_mode) {
      document.querySelector('body').classList.add('dark');
    } else {
      document.querySelector('body').classList.remove('dark');
    }
  },
  toggleMenuStyle(state, value) {
    //horizontal|vertical|collapsible-vertical
    value = value || '';
    localStorage.setItem('menu_style', value);
    state.menu_style = value;
    if (!value || value === 'vertical') {
      state.is_show_sidebar = true;
    } else if (value === 'collapsible-vertical') {
      state.is_show_sidebar = false;
    }
  },
  toggleLayoutStyle(state, value) {
    //boxed-layout|large-boxed-layout|full
    value = value || '';
    localStorage.setItem('layout_style', value);
    state.layout_style = value;
  },

  ///////////////////////////////////////////////////
  // Mutations from application              /////////
  ///////////////////////////////////////////////////

  // Cambia el idioma de la aplicacion
  setLocalesLan(state) {
    state.countryList = [
      { code: 'es', name: 'Spanish' },
      { code: 'en', name: 'English' },
    ];
  },

  // User from state
  setUserS(state, value) {
    state.user = value;
  },

  setAppS(state, value) {
    state.app = value;
  },

  setNotificationS(state, value) {
    state.notification = value;
  },

  updateNotificationS(state, id, status) {
    const notificationIndex = state.notification.findIndex((n) => n.id === id);
    if (notificationIndex !== -1) {
      state.notification[notificationIndex].status = status;
    }
  },

  setMenuMutation(state, value) {
    state.menu = value;
  },

  setShopsUserMutation(state, value) {
    state.shopsUser = value;
  },

  setMenusRolMutation(state, value) {
    state.menusrol = value;
  },
  setSubMenuMutation(state, value) {
    state.submenu = value;
  },

  logShops_UserMutation(state) {
    state.shopsUser = [];
  },

  logOut_MenuMutation(state) {
    const menu = [];
    state.menu = menu;
  },

  logOut_MenusRolMutation(state) {
    state.menusrol = [];
  },
  //Delte User from state
  logOut_UserS(state) {
    const user = {
      email: '',
      id: '',
      idRol: '',
      idApp: '',
      idShop: '',
      is_login: false,
      tokens: {
        access: '',
        refresh: '',
      },
    };
    localStorage.removeItem('token');
    state.user = user;
  },

  setOnboardingBepayS(state, value) {
    state.onBoardingBepay = value;
  },
  setOnboardingImgBepayS(state, value) {
    state.onBoardingBepayImage = value;
  },
  
};

export default mutations;
