<template>
  <div class="layout-px-spacing">
    <div class="row layout-top-spacing">
      <div class="d-flex justify-content-end m-2">
        <button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#modalAddPP">
          Agregar Producto 
        </button>

      </div>
      
      <div v-if="waiting" :key="waiting" class="d-flex justify-content-center">
        <LoadingSpinner />
      </div>

      <div v-else-if="groupeds && groupeds.length === 0" class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
        <div class="card mt-3">
          <div class="d-flex align-items-center">
            <div class="flex-1 p-5">
              <img src="@/assets/images/svg/empty_cart.svg" class="img-fluid" alt="No products" />
            </div>
            <div class="flex-2 d-flex flex-column align-items-start" style="padding: 40px">
              <h3 style="font-weight: 600; color: #FF6C22">No cuentas con pines agrupados para tus productos digitales</h3>
              <span class="mt-2" style="font-size: 15px"> Presiona Agregar producto y agrega tu primer grupo para tus pines </span>
              <span class="mt-2" style="font-size: 15px"> <strong> Recuerda: </strong> Para poder agregar un producto digital, debes primero
                contar con pines del mismo.
              </span>

            </div>
          </div>
        </div>
      </div>
      <!-- Test -->
      <div v-else class="panel br-6 p-0 mt-4">
        <div class="d-flex justify-content-center mt-3 mb-2">
          <h6 class="pt-2">Lista de productos Digitales con grupos</h6>
        </div>
        <div class="custom-table">
          <v-client-table :data="groupeds" :columns="columnsTable" :options="table_options">
            <template #ref="props">
              <div class="d-flex justify-content-center">
                <img :src="props.row.ref" style="width: 50px; height: 50px; border-radius: 50%">
              </div>
            </template>

            <template #acciones="props">
              <div class="d-flex justify-content-start">
                <div
                  id="ddlPriority"
                  class="btn dropdown-toggle btn-icon-only"
                  data-bs-toggle="tooltip"
                  :key="props.row.status"
                  :title="props.row.status == 1 ? $t('Enabled') : $t('Disabled')"
                  @click="groupEnable(props.row)"
                >
                  <fa :icon="['fas', props.row.status == 1 ? 'eye' : 'eye-slash']" class="btn-icon" > </fa>
                </div>

                <div
                  class="btn dropdown-toggle btn-icon-only"
                  @click="groupModify(props.row)"
                  type="button" data-bs-toggle="modal" data-bs-target="#modalUpdate"

                >
                  <fa :icon="['fas', 'edit']" class="btn-icon"> </fa>
                </div>

                <div
                  class="btn dropdown-toggle btn-icon-only"
                  @click="groupModify(props.row)"
                  type="button" data-bs-toggle="modal" data-bs-target="#modalDelete"
                >
                  <fa :icon="['fas', 'trash']" class="btn-icon"> </fa>
                </div>
              </div>
            </template>
          </v-client-table>
        </div>
      </div>
    </div>
    
    <!-- Modal Agregar-->
    <div class="modal fade" id="modalAddPP" tabindex="-1" role="dialog" aria-labelledby="modalAddPPLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalAddPPLabel">Agregar Producto</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body row">
              
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <p> Producto </p>
                <div class="form-group">
                  <div class="input-group">
                    <select
                      :key="digitalProducts.length"
                      :required="digitalProducts.length > 0"
                      :disabled="false"
                      :searchable="true"
                      v-model="selectedDigitalP.id"
                      @change="emitDigitalP"
                      class="form-select w-100"
                    >
                      <option disabled value=""> Seleccione un producto </option>
                      <option v-for="i in digitalProducts" :value="i.id" :key="i.id">
                        {{ i.type }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-6" />
              <Inputfaform :key="groupedProduct.name" label="Nombre del Grupo"   icon="award" :disabled="false" type="text" placeholder="Name"   :controller="groupedProduct.name"   @updateValue="emitName" />
              <Inputfaform :key="groupedProduct.amount" label="Cantidad" icon="hashtag"         :disabled="false" type="number" placeholder="Cantidad" :controller="groupedProduct.amount" @updateValue="emitCantidad" />
              <Inputfaform :key="groupedProduct.price" label="Precio"   icon="dollar-sign"     :disabled="true"  type="number" placeholder="Precio" :controller="groupedProduct.price" />

            </div>
            <div class="modal-footer d-flex justify-content-end">
              <button type="button" class="btn btn-success" @click="handleCreateGroup"> Agregar</button>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal Actualizar -->
    <div class="modal fade" id="modalUpdate" tabindex="-1" role="dialog" aria-labelledby="modalUpdateLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalUpdateLabel">Actualizar grupo de Pines</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body row">

              <InputSelect
                :key="digitalProducts"
                label="Producto"
                placeholder="Seleccione un producto"
                :required="digitalProducts.length > 0"
                :controller="selectedDigitalP.id"
                :listController="digitalProducts"
                :disabled="true"
              />
              <div class="col-6" />

              <Inputfaform :key="group.groupName"       label="Nombre del Grupo"   icon="award" :disabled="false" type="text" placeholder="Name"   :controller="group.groupName"   @updateValue="emitGroupName" />
              <Inputfaform :key="group.productQuantity" label="Cantidad"           icon="hashtag"         :disabled="false" type="number" placeholder="Cantidad" :controller="group.productQuantity" @updateValue="emitGroupCantidad" />
              <Inputfaform :key="group.groupPrice"      label="Precio"             icon="dollar-sign"     :disabled="true"  type="number" placeholder="Precio" :controller="group.groupPrice" />

            </div>
            <div class="modal-footer d-flex justify-content-between">
              <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" aria-label="Close"> Cancelar </button>
              <button type="button" class="btn btn-primary"  :disabled="dsbutton" @click="handleEditGroup"> Editar </button>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal Borrar -->
    <div class="modal fade" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="modalDeleteLabel" aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalDeleteLabel">Elimnar Grupo</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center justify-content-center">
              <span> ¿Estás seguro de que deseas eliminar este grupo de Pines? </span>
              <h6 class="mt-2" style="font-weight: bold">{{ group.groupName }}</h6>
            </div>
            <div class="modal-footer d-flex justify-content-between">
              <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" aria-label="Close"> Eliminar</button>
              <button type="button" class="btn btn-primary"         @click="handleDeleteGroup"> Aceptar </button>
            </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
  // Importacion de librerias
  import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useMeta } from '@/composables/use-meta';
  import LoadingSpinner from '@/views/components/components_forms/loadingSpinner.vue';
  import Inputfaform from '@/views/components/components_forms/input_faform4.vue';
  import InputSelect from '@/views/components/components_forms/input_select6.vue';

  import { formatoSaldo } from '@/constants/constants';

  // Variables
  const store = useStore();
  const waiting  = ref(true);
  const dsbutton = ref(false);

  const user = ref(store.getters.getUser);
  // Trabajo
  const products        = ref([]);
  const digitalProducts = ref([]);
  const groupeds        = ref([]);
  const selectedDigitalP= ref({ id: 0 });
  const group           = ref({});
  const groupedProduct = ref( { id:0, name   : "", amount : 0, price  : 0, });



  var columnsTable = ref(['ref', 'producto','precio_unitario', 'grupo',  'precio', 'acciones']);
  var table_options = ref({
    perPage: 10,
    perPageValues: [5, 10, 20, 50],
    skin: 'table ',
    columnsClasses: { actions: 'actions text-center' },
    sortable: [],
    pagination: { nav: 'scroll', chunk: 5 },
    texts: {
      count: 'Mostrando {from} de {to} de {count}',
      filter: '',
      filterPlaceholder: 'Buscar...',
      limit: 'Resultados:',
    },
    sortable: ['_id', 'producto', 'acciones', 'precio', 'grupo', 'precio_unitario'],
    sortIcon: {
      base: 'sort-icon-none',
      up: 'sort-icon-asc',
      down: 'sort-icon-desc',
    },
    resizableColumns: false,
  });


  
  const emitGroupName = (controller) => { 
    group.value.groupName = controller 
  };
  const emitGroupCantidad = (controller) => {
    group.value.productQuantity = controller;
    if (selectedDigitalP.value.id !== 0 ) group.value.groupPrice = group.value.productQuantity * selectedDigitalP.value.price;
  }
  const emitDigitalP = () => {
    const product = digitalProducts.value.find((product) => product.id === selectedDigitalP.value.id);
    selectedDigitalP.value = product;
    if (groupedProduct.value.amount > 0 && selectedDigitalP.value) {
      groupedProduct.value.price = groupedProduct.value.amount * selectedDigitalP.value.price;
    }
  }
  const emitName     = (controller) =>  { groupedProduct.value.name = controller };
  const emitCantidad = (controller) => {  
    groupedProduct.value.amount = controller;
    if (selectedDigitalP.value.id !== 0 )  {
      groupedProduct.value.price = groupedProduct.value.amount * selectedDigitalP.value.price;
    }
  };

  onMounted(async () => {
    await fetchPins();
  });

  // obtener todos los productos con pines digitales
  async function fetchPins() {

    const dataRequest = {
      id: user.value.idShop,
      bodyOptions: {
        idApp: user.value.idApp,
      },
    };

    try {
      const { data } = await axiosWS.getShopsDPwithPinsAdmin(dataRequest);
      digitalProducts.value = data.products.map((product) => ({
        id: product.id,        type: product.name,
        price: product.price,  urlImg: product.thumbnail.url,
      }));

      await fectGroups();

    } catch (error) {
      console.error('Error fetching digital pins /  products:', error);
    } finally {
      waiting.value = false;
    }
  }


  // obtener los grupos
  async function fectGroups() {

    const dataRequest = { id: user.value.idShop };

    try {
      const { data } = await axiosWS.getAllProductGrouped(dataRequest);
      if ( data.error === false ) {

        groupeds.value =  data.productGroupeds.map((group) => {
          const matchedProduct = digitalProducts.value.find(
            (product) => product.id === group.idProduct
          );

          if (matchedProduct) {
            return {
              ...group,
              ref: matchedProduct.urlImg,
              precio_unitario: matchedProduct.price,
              producto: matchedProduct.type,
              grupo: group.groupName,
              precio: group.groupPrice
            };
          }
          return group;
        });
      }
    } catch (error) {
      console.error('Error fetching digital pins /  products:', error);
    }
  }


  // manipulador para crear el grupo 
  async function handleCreateGroup() {
    dsbutton.value = true; 
    try {
      
      const data = {
        bodyOptions: {   
          idProduct      : selectedDigitalP.value.id,
          groupName      : groupedProduct.value.name,
          productQuantity: +groupedProduct.value.amount,
          groupPrice     : +groupedProduct.value.price,
          status         : 1,
        }
      };
      console.log(data);

      const res = await axiosWS.createProductGrouped(data);
      console.log(res);
      if (res.status == 200 && res.data.error == false ) {
        // Cerrar el modal si la respuesta es exitosa
        const modalElement = document.getElementById('modalAddPP');
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide(); // Esto cierra el modal

        waiting.value = true;
        await fectGroups();
        waiting.value = false;
      }
    } catch (error) {
      console.log(error);
      showMessage('Se presento un error intente mas tarde', 'danger');
    } finally{
      dsbutton.value = false; 
    }
  }


  // habilitar o deshabilitar
  async function groupEnable(obj) {
    dsbutton.value = true; 
    try {
      
      const data = {
        bodyOptions: {
          id             : +obj.id,     
          idProduct      : obj.idProduct,
          groupName      : obj.groupName,
          productQuantity: +obj.productQuantity,
          groupPrice     : +obj.groupPrice,
          status         : obj.status === 1 ? 0 : 1,
        }
      };

      const res = await axiosWS.updateProductGrouped(data);

      if (res.status == 200 && res.data.error == false ) {
        showMessage('Estado actualizado exitosamente.', 'success');
        await fectGroups();
      }
    } catch (error) {
      console.log(error);
      showMessage('Se presento un error intente mas tarde', 'warning');
    } finally{
      dsbutton.value = false; 
    }
  }


  // manipulador de edit
  async function handleEditGroup() {

    dsbutton.value = true; 
    try {
      
      const data = {
        bodyOptions: {
          id             : +group.value.id,     
          idProduct      : group.value.idProduct,
          groupName      : group.value.groupName,
          productQuantity: +group.value.productQuantity,
          groupPrice     : +group.value.groupPrice,
          status         : 1,
        }
      };

      const res = await axiosWS.updateProductGrouped(data);
      if (res.status == 200 && res.data.error == false ) {
        showMessage('Estado actualizado exitosamente.', 'success');

        // Cerrar el modal si la respuesta es exitosa
        const modalElement = document.getElementById('modalUpdate');
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide(); // Esto cierra el modal

        waiting.value = true;
        await fectGroups();
        waiting.value = false;
      }
    } catch (error) {
      console.log(error);
      showMessage('Se presento un error intente mas tarde', 'danger');
    } finally{
      dsbutton.value = false; 
    }
  }


  // manipulador de delete
  async function handleDeleteGroup() {
    dsbutton.value = true; 
    
    try {
      const data = {
        bodyOptions: {
          id: group.value.id.toString(),
          idApp: +user.value.idShop
        }
      };
      console.log(data);

      const res = await axiosWS.deleteProductGrouped(data);
      console.log(res);
      if (res.status == 200 && res.data.error == false ) {
        showMessage('Estado actualizado exitosamente.', 'success');
        // Cerrar el modal si la respuesta es exitosa
        const modalElement = document.getElementById('modalDelete');
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide(); // Esto cierra el modal
        
        waiting.value = true;
        await fectGroups();
        waiting.value = false;
      }
    } catch (error) {
      console.log(error);
      showMessage('Se presento un error intente mas tarde', 'warning');
    } finally{
      dsbutton.value = false; 
    }
  }

  
  // modificar el grupo
  function groupModify(obj) {
    console.log(obj)
    group.value = obj;
    selectedDigitalP.value.id = obj.idProduct;
    selectedDigitalP.value.price = obj.precio_unitario;
  }


  const showMessage = (msg = '', type = '') => {
    const toast = window.Swal.mixin({ toast: true, position: 'top', showConfirmButton: false, timer: 3000 });
    toast.fire({ icon: type, title: msg, padding: '10px 20px' });
  };


</script>

<style>
  .flex-1{
    flex: 1;
  }

  .flex-2{
    flex: 2;
  }

  .btn-icon {
    padding-right: 5px !important;
    padding-left: 10px !important;

    height: 15px !important; 
    width: 15px  !important; 
    padding-top: 12px !important;
  }
</style>