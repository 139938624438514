<template>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
    <p>{{ $t(label) }}</p>
    <div class="form-group">
      <div class="input-group">
        <select :required="required" :disabled="disabled" v-model="controller" @change="uploadEmit" class="form-select w-100">
          <option disabled value="">{{ $t(placeholder) }}</option>
          <option v-for="item in filtered" :value="item.id" :key="item.id">
            {{ item.type }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps(['label', 'placeholder', 'disabled', 'controller', 'filter', 'keyFilter', 'required', 'listController']);

  const filtered = computed(() => {
    if (props.filter !== '') {
      return props.listController.filter((e) => e[props.keyFilter] === props.filter);
    } else {
      return props.listController;
    }
  });

  const emit = defineEmits(['updateValue']);

  const uploadEmit = () => {
    emit('updateValue', props.controller);
  };
</script>
