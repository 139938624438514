<template>
  <div class="mx-5 mt-3 mb-3 centerLoadingSpinner">
    <div class="spinner-border text-success align-self-center loader-lg">Loading...</div>
  </div>
</template>

<style>

  .centerLoadingSpinner{
    position: absolute;
    right: 50%;
    top: 45%;
    justify-content: center;
    align-self: center;
  }

  @media (max-width: 450px) {
    .centerLoadingSpinner{
      position: absolute;
      right: 35%;
      justify-content: center;
      align-self: center;
    }
  }
</style>