<template>
  <!--  Modales -->
  <a class="dropdown-item displaynone" id="autorizationAdmin" data-bs-toggle="modal" data-bs-target="#modalAutorization"></a>

  <div class="modal fade" id="modalAutorization" tabindex="-1" role="dialog" aria-labelledby="modalAutorization" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalAutorization">
            <fa v-if="$store.state.dark_mode == 'light'" :icon="['fas', 'right-from-bracket']" class="ml-4" style="color: black"> </fa>
            <fa v-else :icon="['fas', 'right-from-bracket']" class="ml-4" style="color: white"> </fa>
            {{$t("Action Denied")}}
          </h5>
        </div>
        <div class="modal-body">
          <p class="modal-text"> {{$t("You don't have administrator access. Please, request the creation of your role for corresponding shop.")}} </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal" data-bs-dismiss="modal" @click="logOut">Volver</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';

  // Para implementar cambios en el header, ir al archivo _navbar.scss linea 22
  const props = defineProps({
    alertDisplay: { default: true, type: Boolean },
  });

  onMounted(() => {
    if (props.alertDisplay == true) {
      const expiration = document.getElementById('autorizationAdmin');
      expiration.click();
    }
  });

  const logOut = () => {
    store.dispatch('logOut_User');
    store.dispatch('setOnboardingBepay',0);

    store.dispatch('logOut_ShopsUser');
    store.dispatch('logOut_Menu');
    store.dispatch('logOut_MenusRol');
    router.replace('/log_out');
  };
</script>
