const actions = {
  // llama las mutatciones
  setLocales({ commit }) {
    commit('setLocalesLan');
  },

  setUser({ commit }, value) {
    commit('setUserS', value);
  },

  setApp({ commit }, value) {
    commit('setAppS', value);
  },

  setNotification({ commit }, value) {
    commit('setNotificationS', value);
  },

  updateNotification({ commit }, id, status) {
    commit('updateNotificationS', id, status);
  },

  setMenu({ commit }, value) {
    commit('setMenuMutation', value);
  },

  setSubMenu({ commit }, value) {
    commit('setSubMenuMutation', value);
  },

  setMenusRol({ commit }, value) {
    commit('setMenusRolMutation', value);
  },

  setShopsUser({ commit }, value) {
    commit('setShopsUserMutation', value);
  },

  //logout
  logOut_Menu({ commit }) {
    commit('logOut_MenuMutation');
  },

  logOut_ShopsUser({ commit }) {
    commit('logShops_UserMutation');
  },

  logOut_MenusRol({ commit }) {
    commit('logOut_MenusRolMutation');
  },

  logOut_User({ commit }) {
    commit('logOut_UserS');
  },

  setOnboardingBepay({commit}, value) {
    // console.log("setOnboarding--->", value)
    commit('setOnboardingBepayS', value)
  },

  setOnboardingImgBepay({commit}, value) {
    // console.log("setOnboarding--->", value)
    commit('setOnboardingImgBepayS', value)
  }
};

export default actions;
