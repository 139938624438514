<template>
  <div class="dropdown nav-item notification-dropdown btn-group">
    <a href="javascript:;" id="ddlnotify" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
      <fa :icon="['fas', 'bell']" class="feather feather-bell"> </fa>
      <span class="badge badge-danger" style="width: 15px !important; height: 15px !important; border-radius: 50% !important; background-color: red; right: -5px; top: 2px; font-weight: 600">{{
        count < 4 ? count : '3+'
      }}</span>
    </a>
    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlnotify">
      <li role="presentation" v-for="notification in notifications" :key="notification.id">
        <!-- <p>{{ notifications }}</p> -->
        <router-link :to="notification.urlLink" class="dropdown-item" @click="updateNotificationStatus(notification)">
          <div class="media server-log">
            <div class="media-aside align-self-start">
              <fa :icon="['fas', notification.icon]" class=""> </fa>
            </div>
            <div class="media-body">
              <div class="data-info">
                <h6 class="">{{ notification.title }}</h6>
                <p class="">{{ notification.description }}</p>
              </div>

              <div class="icon-status">
                <fa :icon="['fas', notification.status == '0' ? 'times' : 'check']" class="feather feather-eye"> </fa>
              </div>
            </div>
          </div>
        </router-link>
      </li>
      <li role="presentation"><hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider" /></li>
      <li role="presentation" v-if="count >= 0">
        <router-link to="/Notifications" class="dropdown-item">
          <div class="media server-log">
            <div class="media-aside align-self-start">
              <fa :icon="['fas', 'info-circle']" class=""> </fa>
            </div>
            <div class="media-body">
              <div class="data-info">
                <h6 class="">{{ $t('Go to notification center') }}</h6>
              </div>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();
  const user = ref(store.getters.getUser);
  const notifications = ref(store.getters.getNotification);
  const count = ref(0);
  onMounted(() => {
    getNotifications();
  });

  function getNotifications() {
    const data = {
      bodyOptions: {
        idApp: user.value.idApp,
        iduser: null,
        idShop: user.value.idShop,
      },
    };

    // console.log(data.bodyOptions);

    var GetNotificationsRes = axiosWS.getNotifications(data);
    GetNotificationsRes.then((res) => {
      // console.log(res)
      if (res.status == 200) {
        const { data } = res;
        if (data.count > 0) {
          count.value = data.count;
          var notification = data.notifications;
          if (notification.length > 3) {
            notification = data.notifications.slice(0, 3);
          }
          store.dispatch('setNotification', notification);
          notifications.value = store.getters.getNotification;
        } else {
          notifications.value = [];
          store.dispatch('setNotification', notification);
          notifications.value = store.getters.getNotification;
        }

        // console.log('lo que llega del backend nuevo', data);
        // console.log('almaceno en mi variable', notification);
        // console.log('lo que llega', listNotifications.value);
      }
    });
  }

  function updateNotificationStatus(notification) {
    if (notification.status == 0) {
      notification.status = 1;
    }
    // console.log('status al backend', notification.status);
    const data = {
      bodyOptions: {
        id: +notification.id,
        status: +notification.status,
      },
    };
    var PostNotificationStatusRes = axiosWS.updateNotificationStatus(data);
    PostNotificationStatusRes.then((res) => {
      if (res.status == 200) {
        store.dispatch('updateNotification', notification.id, notification.status);
      }
    });
  }
</script>

<style scoped>
  .badge-counter {
    right: -5px;
    font-weight: 600;
    width: 19px !important;
    height: 19px !important;
    border-radius: 50% !important;
    padding: 5px 0 !important;
    font-size: 16px !important;
    background-color: brown;
  }
</style>
