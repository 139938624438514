<template>
  <div class="layout-px-spacing dash_1">
    <breadcrumb name="Digital products" />
    <!-- Contenido -->
    <ListGroupedProducts/>
  </div>

  <autorizationAdmin :key="autorization" :alertDisplay="autorization" />
  <logOut></logOut>
</template>


<script setup>
  // Importacion de librerias
  import { useStore }       from 'vuex';
  import { useMeta }        from '@/composables/use-meta';
  import { onMounted, ref } from 'vue';
  import breadcrumb         from '@/views/components/Maincomponents/breadCrumb.vue';
  // Importacion de Alerts y components
  import {rutaPermitida}   from '@/constants/constants';
  import autorizationAdmin from '@/views/logOut/autorizationAdmin.vue';
  import logOut            from '@/views/logOut/logOut.vue';
  import ListGroupedProducts from './components/ListGroupedProducts.vue'; 

  var URLactual = window.location;
  //Constantes del scrip
  const store            = useStore();
  const user             = store.getters.getUser;
  const localstore       = store.getters.getLocalStore;
  const autorization     = ref(false);

  
  useMeta( localstore == 'es' ?  { title: 'Grupos' }: { title: 'Groups' } );

  onMounted(() => {
  });

  
</script>
